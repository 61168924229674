<template>
  <div class="page-footer">
    <footer class="footer">
      <div class="visa">
        <div class="item-top">
          <div class="item-left">
            <p class="item-text title-strong">公司地址</p>
            <p class="item-text">深圳市宝安区</p>
            <p class="item-text">西乡宝田一路50号</p>
            <span><img src="../assets/images/footer-icon3.gif" alt="" /></span>
          </div>
          <div class="item-center">
            <p class="item-text title-strong">联系电话</p>
            <p class="item-text">18664884856</p>
            <p class="item-text">工作时间：周一至周五</p>
            <p class="item-text">09:00-18:00</p>
            <span><img src="../assets/images/footer-icon4.gif" alt="" /></span>
          </div>
          <div class="item-right">
            <p class="item-text title-strong">邮件地址</p>
            <p class="item-text">shulong2020@126.com</p>
            <span><img src="../assets/images/footer-icon2.gif" alt="" /></span>
          </div>
          <div class="item-position">
            <div class="qrcode-1">
              <p class="item-text title-strong">人工服务咨询</p>
              <p class="item-img">
                <img src="https://www.xueshuibao.com/app/static/images/zixun.jpg" width="150" height="150"/>
              </p>
            </div>
            <div>
              <p class="item-text title-strong">扫一扫关注微信服务号</p>
              <p class="item-img">
                <img src="../assets/images/qrcode.jpg" width="150" height="150"/>
              </p>
            </div>
          </div>
        </div>
        <div class="item-bottom">
          <p class="item-text"><span><img src="../assets/images/footer-icon1.gif"/></span>ICP备案号：<a style="color:#666; text-decoration:none" rel="noopener" href="https://beian.miit.gov.cn" target="_blank" >粤ICP备2022020923号</a></p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'layoutFooter'
}
</script>

<style scoped lang="scss">
  .page-footer{
    margin-top: 30px;
  }
  footer{
    background:#eef3fa;
    text-align: left;
  }
  footer .visa{
    width: 1200px;
    margin: 0 auto;
  }
  footer .visa .item-top{
    padding:50px 0 40px;
    margin:0 50px;
    overflow:hidden;
    position: relative;
  }
  footer .visa .item-top>div{
    float:left;
    margin-right:40px;
    padding:10px 35px;
    position:relative;
  }
  footer .visa .item-top>div>p{
    color:#5e6068;
    font-size:12px;
    line-height:22px;
  }
  footer .visa .item-top>div>p.title-strong{
    font-size:15px;
    line-height:40px;
  }
  footer .visa .item-top>div>span{
    position:absolute;
    left:0;
    top:30px;
    width:20px;
    height:20px;
  }
  footer .visa .item-top>div>span img{
    width:100%;
  }
  footer .visa .item-top>div.item-position{
    position:absolute;
    top: 10px;
    right:0;
    margin-right:0;
    padding-right: 0;
    display: flex;
    text-align: center;
    .qrcode-1{
      margin-right: 70px;
    }
  }
  footer .visa .item-bottom{
    text-align: center;
    color:#5e6068;
    font-size:14px;
    padding-bottom:40px;
    line-height:28px;
  }

  footer .visa .item-bottom p.item-text span img{
    vertical-align: text-top;
  }
</style>
