<template>
  <div class="layout">
    <el-scrollbar style="height: 100%">
      <layout-header></layout-header>
      <div style="height: 84px;"></div>
      <!--<Banner></Banner>-->
      <router-view></router-view>
      <layout-footer></layout-footer>
    </el-scrollbar>

    <Login></Login>
  </div>
</template>

<script>
import LayoutHeader from './layoutHeader'
// import Banner from './banner'
import LayoutFooter from './layoutFooter'
import Login from '../components/login'
export default {
  name: 'index',
  components: {
    LayoutHeader,
    // Banner,
    LayoutFooter,
    Login
  }
}
</script>

<style scoped lang="scss">
.layout{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  // background-color: #2c3e50;
}
</style>
