<template>
  <div class="page-head">
    <div class="container">
      <div class="header">
        <div class="left-logo"><img src="../assets/x-logo.png" alt="" width="200px"></div>
        <div class="right-menu">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#2c3e50"
                   :unique-opened="true" active-text-color="#04599a" router>
            <el-menu-item index="/">首页</el-menu-item>
            <el-submenu index="2" popper-class="outer-class-name">
              <template slot="title">纳税测评</template>
              <el-menu-item index="/offlineMeasure">离线测评</el-menu-item>
              <el-menu-item index="/onlineMeasure">在线测评</el-menu-item>
            </el-submenu>
            <el-menu-item index="/policyRetrieve">政策检索</el-menu-item>
            <el-menu-item index="/ratePayCourse">纳税课堂</el-menu-item>
            <el-menu-item index="/question">热点问答</el-menu-item>
            <el-menu-item index="/userCenter" v-if="userInfo">个人中心</el-menu-item>
          </el-menu>
          <div class="login-entry" @click="doLogin">{{userInfo ? '退出登录' : '登录'}}
            <img v-if="userInfo" :src="userInfo.headimgurl" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'layoutHeader',
  data () {
    return {}
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    }),
    activeIndex () {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) { // 注意这里很重要
        return meta.activeMenu
      }
      return path
    }
  },
  methods: {
    doLogin () {
      if (!this.userInfo) {
        this.$store.commit('SET_SHOW_LOGIN', true)
      } else {
        this.$confirm('确认退出登录？').then(_ => {
          this.$store.dispatch('resetToken').then(() => {
            this.$router.push('/')
          })
        })
      }
    },
    ...mapMutations({
      SET_SHOW_LOGIN: 'setShowLogin'
    })
  },
  created () {
    // this.activeIndex = this.$route.path
  }
}
</script>

<style scoped lang="scss">
.page-head{
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  .container{
    max-width: 1200px;
    min-width: 980px;
    height: 84px;
    margin: 0 auto;
    .header{
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .left-logo {
        display: flex;
        align-items: center;
      }
      .right-menu{
        display: flex;
        align-items: center;
        font-size: 17px;
        .el-menu-demo{
          border-bottom: none;
          .el-menu-item, ::v-deep .el-submenu .el-submenu__title{
            font-size: inherit;
            height: 84px;
            line-height: 84px;
          }
        }
        .login-entry{
          padding: 0 20px;
          line-height: 84px;
          cursor: pointer;
          display: flex;
          align-items: center;
          img{
            width: 50px;
            height: 50px;
            border-radius: 100%;
          }
        }
        .el-menu--horizontal>.el-menu-item{
          height: 84px;
          line-height: 84px;
          box-sizing: border-box;
          &:hover{
            color: #fff !important;
            background-color: #005ead;
          }
          &.is-active{
            color: #fff !important;
            background-color: #005ead;
          }
        }
        .el-menu--horizontal {
          .el-submenu{
            &.is-active{
              background-color: #005ead;
              ::v-deep .el-submenu__title{
                color: #fff !important;
                i{
                  color: #fff;
                }
              }
            }
            &.is-opened{
              background-color: #005ead !important;
              color: #fff !important;
              ::v-deep .el-submenu__title{
                color: #fff !important;
              }
            }
            ::v-deep .el-submenu__title:hover {
              background-color: #005ead !important;
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
.outer-class-name{
  font-size: 16px;
  .el-menu-item{
    font-size: inherit;
  }
}
</style>
