<template>
  <el-dialog :title="title"
             :visible.sync="dialogVisible"
             width="380px"
             :close-on-click-modal="false"
             :destroy-on-close="true"
             :before-close="handleClose" style="z-index: 2000">
    <div class="login-pswimg">
      <div :class="['login-pswli psw-btna', loginType == 1 ? 'qrcode' : 'computer']">
        <img :src="logo" alt="" width="42px" @click="changeLogin">
      </div>
    </div>
    <div v-show="loginType === 1">
      <el-form :model="form" ref="form">
        <el-form-item prop="phone">
          <el-input v-model="form.phone" autocomplete="off" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input type="password" v-model="form.pass" autocomplete="off" placeholder="6至16位密码,区分大小写"></el-input>
        </el-form-item>
      </el-form>
      <div class="login-type-wrap">
        <a class="link forget">忘记密码</a>
        <a class="link">手机验证码登录</a>
      </div>
      <p class="error-tips" v-show="loginError">{{errTips}}</p>
      <div class="btns-wrap">
        <el-button class="btn" type="primary" @click="submitForm">登录</el-button>
        <el-button class="btn" @click="resetForm">注册</el-button>
      </div>
    </div>
    <div class="wxlog-cen" v-show="loginType === 2">
      <div class="wxlog-img" id="login_container">
<!--        <img src="../assets/images/qrcode.jpg">-->
      </div>
      <div class="wxlog-txt">登录即代表同意<span @click="showXiYi=true">&lt;&lt;隐私政策&gt;&gt;</span></div>
    </div>
    <el-dialog
      title="用户协议"
      :visible.sync="showXiYi"
      width="800px"
      :close-on-click-modal="true"
      :show-close="true"
      custom-class="custom-style-content"
      append-to-body
      center>
      <div class="content" style="height: 600px;overflow: auto;line-height: 26px;text-indent: 30px;">
        尊敬的用户，欢迎使用由深圳数隆科技有限公司（下列简称为“数隆科技”或“税易答”）提供的服务。在使用前请您阅读如下服务协议，使用本应用即表示您同意接受本协议，本协议产生法律效力，特别涉及免除或者限制数隆科技责任的条款，请仔细阅读。如有任何问题，可向数隆科技咨询。

        <p>1.服务条款的确认和接受
          通过访问或使用本应用，表示用户同意接受本协议的所有条件和条款。</p>
        <p>2.服务条款的变更和修改
        数隆科技有权在必要时修改服务条款，服务条款一旦发生变更，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以放弃获得的本应用信息服务。如果用户继续享用本应用的信息服务，则视为接受服务条款的变更。本应用保留随时修改或中断服务而不需要通知用户的权利。本应用行使修改或中断服务的权利，不需对用户或第三方负责。
        </p><p>3.用户行为</p>
        <p>3.1.用户账号、密码和安全
        用户一旦注册成功，便成为税易答的合法用户，将得到一个密码和帐号。同时，此账号密码可登录税易答的所有网页和小程序。因此用户应采取合理措施维护其密码和帐号的安全。用户对利用该密码和帐号所进行的一切活动负全部责任；由该等活动所导致的任何损失或损害由用户承担，数隆科技不承担任何责任。 用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通知数隆科技，并且用户在每次连线结束，应结束帐号使用，否则用户可能得不到数隆科技的安全保护。对于用户长时间未使用的帐号，数隆科技有权予以关闭并注销其内容。
        </p><p>3.2.账号注册时的禁止行为</p>
        <p>（1）请勿以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；</p>
        <p>（2）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关的名称；</p>
        <p>（3）请勿注册和其他网友之名相近、相仿的名字；</p>
        <p>（4）请勿注册不文明、不健康名字，或包含歧视、侮辱、猥亵类词语的名字；</p>
        <p>（5）请勿注册易产生歧义、引起他人误解的名字；</p>
        <p>3.3.用户在本应用上不得发布下列违法信息和照片：</p>
        <p>（1）反对宪法所确定的基本原则的；</p>
        <p>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
        <p>（3）损害国家荣誉和利益的；</p>
        <p>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
        <p>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>（8）侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（9）含有法律、行政法规禁止的其他内容的；</p>
        <p>（10）禁止骚扰、毁谤、威胁、仿冒网站其他用户；</p>
        <p>（11）严禁煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
        <p>（12）严禁发布可能会妨害第三方权益的文件或者信息，例如（包括但不限于）：病毒代码、黑客程序、软件破解注册信息。</p>
        <p>（13）禁止上传他人作品。其中包括你从互联网上下载、截图或收集的他人的作品；</p>
        <p>（14）禁止上传广告、横幅、标志等网络图片；</p>
        <p>4.知识产权说明</p>
        <p>4.1.税易答所有的产品、技术、程序、页面（包括但不限于页面设计及内容）以及资料内容（包含但不限于本站所刊载的图片、视频、Flash等）均属于数隆科技的知识产权，仅供本用户交流、学习、研究和欣赏，未经授权，任何人不得擅自使用，否则，将依法追究法律责任。
        </p><p>4.2.税易答用户在web站点或小程序上传的资料内容（包括但不限于图片、视频、Flash、点评等），应保证为原创或已得到充分授权，并由准确性、真实性、正当性、合法性，且不含任何侵犯第三任权益的内容，因抄袭、转载、侵权等行为所产生的纠纷由用户自行解决，本网站不承担任何法律责任。如第三方向税易答投诉用户上传资料或信息不合规或侵权的，税易答无需征得用户同意，可以自行采取删除或其它处置方式。
        个人或单位如认为数隆科技存在侵犯自身合法权益的内容，应准备好具有法律效应的证明材料，及时与数隆科技取得联系，以便数隆科技迅速作出处理。
      </p><p>5.免责声明</p>
        <p>5.1.税易答转载的内容并不代表税易答之意见及观点，也不意味着赞同其观点或证明其内容的真实性。</p>
        <p>5.2.税易答所转载的文字、图片、音频等资料均由税易答注册用户提供， 其真实性、准确性和合法性由信息发布人负责。税易答不提供任何保证且不承担任何法律责任。</p>
        <p>5.3.税易答所转载的文字、图片、音视频等资料，如果侵犯了第三方的知识产权或其它权利，责任由作者或转载者本人承担，税易答对此不承担责任。</p>
        <p>5.4.用户明确并同意其使用税易答网络服务所存在的风险将完全由其本人承担；因其使用税易答网络服务而产生的一切后果也由其本人承担，税易答对此不承担任何责任。</p>
        <p>5.5.除税易答注明之服务条款外，其他因不当使用税易答而导致的任何意外、疏忽、合约毁坏、诽谤、版权或其他知识产权侵犯及其所造成的任何损失，税易答概不负责，亦不承担任何法律责任。</p>
        <p>5.6.对于因不可抗力或因黑客攻击、通讯线路中断等税易答不能控制的原因造成的网络服务中断或其他缺陷，导致用户不能正常使用税易答，税易答不承担任何责任，但将尽力减少因此给用户造成的损失或影响。</p>
        <p>5.7.本声明与国家有关法律法规冲突时，以国家法律法规为准。</p>

        隐私条款
        <p>1.用户信息公开情况说明
        尊重用户个人隐私是数隆科技的一项基本政策。所以，数隆科技不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本应用中的非公开内容，除非有下列情况：</p>
        <p>（1）有关法律规定或数隆科技合法服务程序规定；</p>
        <p>（2）在紧急情况下，为维护用户及公众的权益；</p>
        <p>（3）为维护数隆科技的商标权、专利权及其他任何合法权益；</p>
        <p>（4）其他需要公开、编辑或透露个人信息的情况；
        在以下（包括但不限于）几种情况下，数隆科技有权使用用户的个人信息：</p>
        <p>（1）在进行促销或抽奖时，数隆科技可能会与赞助商共享用户的个人信息，在这些情况下数隆科技会在发送用户信息之前进行提示，并且用户可以通过不参与来终止传送过程；</p>
        <p>（2）数隆科技可以将用户信息与第三方数据匹配；</p>
        <p>（3）数隆科技会通过透露合计用户统计数据，向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述数隆科技的服务；</p>
        <p>2.隐私权政策适用范围</p>
        <p>（1）用户在登录本应用服务器时留下的个人身份信息；</p>
        <p>（2）用户通过本应用服务器与其他用户或非用户之间传送的各种资讯；</p>
        <p>（3）本应用与商业伙伴共享的其他用户或非用户的各种信息；</p>
        <p>3.资讯公开与共享</p>
        <p>数隆科技不会将用户的个人信息和资讯故意透露、出租或出售给任何第三方。但以下情况除外：</p>
        <p>（1）用户本人同意与第三方共享信息和资讯;</p>
        <p>（2）只有透露用户的个人信息和资讯，才能提供用户所要求的某种产品和服务;</p>
        <p>（3）应代表本应用提供产品或服务的主体的要求提供（除非我们另行通知，否则该等主体无权将相关用户个人信息和资讯用于提供产品和服务之外的其他用途）：根据法律法规或行政命令的要求提供;因外部审计需要而提供;用户违反了本应用服务条款或任何其他产品及服务的使用规定;经本站评估，用户的帐户存在风险，需要加以保护。
        </p><p>4.Cookies、日志档案和webbeacon
        通过使用cookies，本应用向用户提供简单易行并富个性化的网络体验。cookies能帮助我们确定用户连接的页面和内容，并将该等信息储存。我们使用自己的cookies和webbeacon，用于以下用途：</p>
        <p>（1）记住用户身份。例如：cookies和webbeacon有助于我们辨认用户作为我们的注册用户的身份，或保存用户向我们提供有关用户的喜好或其他信息；</p>
        <p>（2）分析用户使用我们服务的情况。我们可利用cookies和webbeacon来了解用户使用我们的服务进行什么活动、或哪些网页或服务最受欢迎； 我们为上述目的使用cookies和webbeacon的同时，可能将通过cookies和webbeacon收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用我们的服务并用于广告服务。用户可以通过浏览器或用户选择机制拒绝或管理cookies或webbeacon。但请用户注意，如果用户停用cookies或webbeacon，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。
        </p><p>（3）当你使用本站的服务时，我们的主机会自动记录用户的浏览器在访问网站时所发送的信息和资讯。主机日志资讯包括但不限于用户的网路请求、IP地址、浏览器类型、浏览器使用的语言、请求的日期和时间，以及一个或多个可以对用户的浏览器进行辨识的cookie。
      </p><p>5.账户删除申请</p>
        <p>用户有权在任何时候编辑用户在数隆科技的帐户信息和资讯，用户也可以填写相关申请表格，要求删除个人帐户，但是用户无条件同意在你的帐户删除后，该帐户内及与该帐户相关的信息和资讯仍然保留在本网站档案记录中，
        除上述第三条规定的情况外，数隆科技将为用户保密。</p>
      </div>
<!--      <span slot="footer" class="dialog-footer">
        <el-button @click="showXiYi = false" style="margin-right: 20px;width: 100px;">不 同 意</el-button>
        <el-button type="primary" @click="centerDialogVisible = false" style="margin-left: 20px;width: 100px;">同 意</el-button>
      </span>-->
    </el-dialog>
  </el-dialog>

</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'login',
  data () {
    return {
      // dialogVisible: true,
      loginType: 2, // 1手机号,2微信扫码
      errTips: '请输入账号和密码',
      loginError: false,
      form: {
        phone: '',
        pass: ''
      },
      showXiYi: false,
      obj: ''
    }
  },
  computed: {
    title () {
      return this.loginType === 1 ? '登录' : '微信登录'
    },
    logo () {
      return this.loginType === 1 ? require('../assets/psw.png') : require('../assets/dn.png')
    },
    ...mapState({
      dialogVisible: (state) => state.showLogin
    })
  },
  methods: {
    changeLogin () {
      this.loginType = this.loginType === 1 ? 2 : 1
    },
    handleClose (done) {
      this.$store.commit('SET_SHOW_LOGIN', false)
      // this.$confirm('确认关闭？')
      //   .then(_ => {
      //     // done()
      //     this.$store.commit('SET_SHOW_LOGIN', false)
      //   })
      //   .catch(_ => {
      //   })
    },
    submitForm () {
      /*
      const { phone, pass } = this.form
      if (!phone || !pass) {
        this.loginError = true
      } else {
        this.loginError = false

        this.$store.commit('SET_USER_INFO', this.form)
        this.$store.commit('SET_SHOW_LOGIN', false)
        this.$message.success('登录成功')
      }
      */
      const data1 = { unionid: 'oBlSo6KiJDgZgsuzMzKVSDpeepm4' }
      this.API.tempLogin(data1).then(({ data }) => {
        this.$store.commit('SET_USER_INFO', this.form)
        this.$store.commit('SET_SHOW_LOGIN', false)
        this.$message.success('登录成功')
      }).finally(() => {
        this.loading = false
      })
    },
    resetForm () {},
    showWechatQrCode () {
      if (window.WxLogin) {
        this.$nextTick(() => {
          const hrefUrl = window.location.href.split('?')[0] // http://www.xueshuibao.com
          let appId = 'wx28f0061d3d266e3b'
          if(hrefUrl.indexOf('www.xueshuibao.com') < 0) {
            appId = 'wxbaf6ca6d4e895b9f'
          }
          const obj = new WxLogin({
            self_redirect: false,
            id: 'login_container',
            //appid: 'wx28f0061d3d266e3b',
            appid : appId,
            scope: 'snsapi_login',
            redirect_uri: encodeURIComponent(hrefUrl),
            state: 'testState',
            style: 'black',
            // http://www.xueshuibao.com/static/css/wechat.style.css
            href: 'https://static.zcool.cn/passport4.0/css/wxCode.css?v=0.1' // 自定义外部样式，需要是https格式
          })
          this.obj = obj
        })
      }
    }
  },
  mounted () {
  },
  watch: {
    dialogVisible (newValue) {
      if (newValue) {
        this.showWechatQrCode()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.login-pswimg {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 42px;
  max-height: 42px;
  cursor: pointer;
  // transform: rotate(-90deg);
  .qrcode{
    transform: rotate(-90deg);
  }
}

.login-type-wrap {
  margin-top: -10px;
  display: flex;
  justify-content: space-between;

  .link {
    color: #20a0ff;
    cursor: pointer;
  }
}
.error-tips{
  text-align: left;
  margin-top: 10px !important;
  color: #ff4949;
}
.btns-wrap {
  display: flex;
  margin-top: 20px;
  .btn {
    flex: 1;
  }
}
.wxlog-cen {
  .wxlog-img{
    ::v-deep iframe {
      height: 283px !important;
      //::v-deep div.info{
      //  display: none !important;
      //}
    }
  }
  .wxlog-txt{
    margin-bottom: 20px;
    span{
      color: #20a0ff;
      cursor: pointer;
    }
  }
}
</style>
